import React, { forwardRef } from "react";

const ScrollableTable = forwardRef((props, ref) => (
  <div className="outer" ref={ref}>
    <div className="inner">{props.children}</div>
  </div>
));

ScrollableTable.displayName = "ScrollableTable";

export default ScrollableTable;
