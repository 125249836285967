import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";

const TableHeaderCell = React.memo(({ children }) => {
  return (
    <th>
      {children ?? ""}
      <SkeletonTheme color="#d07c3b" highlightColor="#ffba85">
        <Skeleton />
      </SkeletonTheme>
    </th>
  );
});

TableHeaderCell.displayName = "TableHeaderCell";

const TableCell = React.memo(({ color = "#e6ebf7" }) => {
  return (
    <td>
      <SkeletonTheme color={color} highlightColor="lightgray">
        <Skeleton height="20px" />
      </SkeletonTheme>
    </td>
  );
});

TableCell.displayName = "TableCell";

const TableSkeleton = ({
  tableHeader = [],
  tableRowsCount = 5,
  tableHeaderCount = 0,
  className = "",
  tableHeaderHeight = "inherit",
}) => {
  const skeletonRows = [];

  for (let i = 0; i < tableRowsCount; i++) {
    let tableCells = [];

    if (tableHeaderCount !== 0) {
      tableCells = [...Array(tableHeaderCount).keys()];
    } else {
      tableCells = [...Array(tableHeader.length).keys()];
    }
    skeletonRows.push(tableCells);
  }
  return (
    <div className={`table-responsive TableSkeleton ${className}`}>
      <table className="table">
        <thead className="thead">
          <tr style={{ height: tableHeaderHeight }}>
            {tableHeaderCount === 0
              ? tableHeader.map((headerColumn) => (
                  <TableHeaderCell key={uuidv4()}>{headerColumn?.["title"] ?? "Table Skeleton"}</TableHeaderCell>
                ))
              : [...Array(tableHeaderCount).keys()].map(() => (
                  <TableHeaderCell key={uuidv4()}>{"Table Skeleton"}</TableHeaderCell>
                ))}
          </tr>
        </thead>
        <tbody>
          {skeletonRows.map((row, index) => {
            return (
              <tr key={uuidv4()}>
                {row.map(() => (
                  <TableCell key={uuidv4()} color={index % 2 === 0 ? "#e6ebf7" : "#f8f8ff"}></TableCell>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
