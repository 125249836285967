import React, { useState, useEffect, forwardRef } from "react";
import Utils from "../../utils/Utils";

const useWindowScroll = ({ ref = null, scrollType = "window" }) => {
  const [scrollTop, setScrollTop] = useState(false);

  function handleElementScroll() {
    if (ref.current.scrollTop + ref.current.offsetHeight >= ref.current.scrollHeight - ref.current.scrollHeight * 0.2) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  }

  function handleWindowScroll() {
    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - document.body.offsetHeight * 0.2) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  }

  useEffect(() => {
    if (scrollType === "window") {
      window.addEventListener("scroll", Utils.throttle(handleWindowScroll, 200));
      handleWindowScroll();
    }

    if (scrollType === "parent" && ref.current) {
      ref.current.addEventListener("scroll", Utils.throttle(handleElementScroll, 500));
      handleElementScroll();
    }

    return () => {
      if (scrollType === "window") {
        window.removeEventListener("scroll", handleWindowScroll);
      } else if (ref.current) {
        ref.current.removeEventListener("scroll", handleElementScroll);
      }
    };
  }, [scrollTop, scrollType, ref.current]);
  return scrollTop;
};

export default useWindowScroll;
