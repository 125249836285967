import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import { FaSortNumericUp, FaSortNumericDown } from "react-icons/fa";
import Utils from "../../utils/Utils";

const svg = (
  <svg
    style={{ marginLeft: "8px" }}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.987474 7.25544C1.06299 7.1113 1.21227 7.021 1.375 7.021H6.625C6.78775 7.021 6.93703 7.1113 7.01251 7.25544C7.08805 7.39958 7.07732 7.57376 6.98468 7.70752L4.35968 11.4992C4.27802 11.6173 4.14356 11.6877 4 11.6877C3.85644 11.6877 3.72198 11.6173 3.64032 11.4992L1.01529 7.70752C0.922666 7.57376 0.91195 7.39958 0.987474 7.25544ZM2.21 7.896L4 10.4816L5.79002 7.896H2.21Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.3125C4.14356 0.3125 4.27802 0.382932 4.35968 0.500969L6.98468 4.29264C7.07732 4.42643 7.08805 4.60057 7.01251 4.74471C6.93703 4.88887 6.78775 4.97917 6.625 4.97917H1.375C1.21227 4.97917 1.06299 4.88887 0.987474 4.74471C0.91195 4.60057 0.922666 4.42643 1.01529 4.29264L3.64032 0.500969C3.72198 0.382932 3.85644 0.3125 4 0.3125ZM2.21 4.10417H5.79002L4 1.51861L2.21 4.10417Z"
      fill="white"
    />
  </svg>
);
const TableHeaderItem = ({
  title,
  ukey,
  hover,
  image,
  customImage,
  onSortClick,
  sortable,
  sortedKey,
  ascOrder,
  defaultSortOnLoad,
  classList,
}) => {
  const styles = sortable
    ? {
        cursor: "pointer",
        background: "#0F131B",
      }
    : { cursor: "default", background: "#0F131B" };
  const [isSticky, setIsSticky] = useState(false);
  const tHeadRef = useRef(null);

  useEffect(() => {
    if (defaultSortOnLoad) {
      if (defaultSortOnLoad.includes(ukey)) {
        setTimeout(() => {
          tHeadRef.current.click();
          tHeadRef.current.click();
        }, 1000);
      }
    }

    return document.getElementsByClassName("topbar-warning").length > 0 ? setIsSticky(true) : undefined;
  }, []);

  return (
    <th
      onClick={() => {
        if (sortable) onSortClick(ukey);
      }}
      ref={tHeadRef}
      style={styles}
      className={isSticky === true ? `sticky-th-1 ${classList.join(" ")}` : `sticky-th-2  ${classList.join(" ")}`}
    >
      <div className="d-flex align-items-center justify-content-center">
        <span>{title}</span>
        {sortable ? svg : null}
      </div>
      {/* {sortedKey === ukey ? (
        ascOrder ? (
          <FaSortNumericUp className="sort-icon" />
        ) : (
          <FaSortNumericDown className="sort-icon" />
        )
      ) : null} */}
      {customImage && <img src={customImage} />}
      {image && (
        <div className="rank">
          <img src={Utils.getAsset("rank-img.png")} alt="" />
        </div>
      )}
      {hover && (
        <>
          {hover.link ? (
            <Link href={`${hover.link}`} className="tooltipLink" onClick={(e) => e.stopPropagation()}>
              <div className="tooltipCon">
                <b className="d-block">{hover.title}</b>
                {hover.text}
              </div>
            </Link>
          ) : (
            <div className="tooltipCon">
              <b className="d-block">{hover.title}</b>
              {hover.text}
            </div>
          )}
        </>
      )}
    </th>
  );
};

export const TableHeader = ({ headers, onSortClick, sortedKey, ascOrder, defaultSortOnLoad = undefined }) => {
  return (
    <>
      {headers?.map((header, i) => (
        <TableHeaderItem
          onSortClick={onSortClick}
          key={"header+" + header.key + i}
          ukey={header.key}
          title={header.title}
          hover={header.tooltip}
          image={header.showImage}
          customImage={header.customImage}
          sortable={header.sortable}
          sortedKey={sortedKey}
          ascOrder={ascOrder}
          defaultSortOnLoad={defaultSortOnLoad}
          classList={header?.classList ? header.classList : []}
        />
      ))}
    </>
  );
};
