import React, { useState, useEffect } from "react";
import useWindowScroll from "./useWindowScroll";

const useInfiniteScroll = ({ array, parentRef = null, itemsPerView = 30, scrollType = "window" }) => {
  const [threshold, setThreshold] = useState(0);
  useEffect(() => {
    setThreshold(window.scrollY * 0.9);
  }, []);
  const [slicedArray, setSlicedArray] = useState([]);
  const [counter, setCounter] = useState(1);
  const offset = useWindowScroll({
    scrollOffset: threshold,
    ref: parentRef,
    scrollType: scrollType,
  });

  const reset = () => {
    setSlicedArray([...array.slice(0, itemsPerView)]);
    setCounter(1);
  };

  const loadMore = () => {
    if (slicedArray.length !== array.length) {
      setSlicedArray([...array.slice(0, (counter + 1) * itemsPerView)]);
      setCounter(counter + 1);
    }
  };

  useEffect(() => {
    if (offset && array.length > 0) {
      loadMore();
    }
  }, [offset, array]);

  useEffect(() => {
    if (array.length > 0) {
      reset();
    }
  }, [array]);

  return [slicedArray];
};

export default useInfiniteScroll;
