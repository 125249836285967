import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Link from "next/link";
import client from "../contentful/Contentful";
import Utils from "../../utils/Utils";

export default function ContentfulBlogs({ user, isFromHomePage = false, tag = "" }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "blog",
        "fields.targetSite[in]": "ShotQualityBets",
        order: "-fields.published",
        // limit: 3,
      })
      .then((response) => {
        let posts = response.items;
        if (tag) {
          posts = posts.filter((it) => JSON.stringify(it.metadata.tags).includes(tag));
        }
        if (posts.length > 3) {
          setPosts(posts.slice(0, 3));
        } else if (posts.length) {
          setPosts(posts);
        } else {
          setPosts(response?.items?.slice(0, 3));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {posts.length && !Utils.isTrader(user) ? (
        <Row style={{ paddingBottom: "20px", marginTop: "5rem" }}>
          <div className="col-12">
            <h2>ShotQualityWire Blog: In-Depth NBA, NCAA, and WNBA News & Analysis</h2>
            <div
              className="d-flex w-100 justify-content-between align-items-center mb-5 flex-wrap"
              style={{ gap: "3rem" }}
            >
              <span style={{ fontSize: "1.3rem" }}>
                Leveraging Top-Tier Basketball Data for Expert Insights, Daily Picks, and Predictions
              </span>
              <Link href={"/blog"} className="btn btn-secondary">
                Read More
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {posts.length > 0 &&
                posts.map((post) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 pb-5" key={post.fields.postUrl}>
                    <BlogCard post={post} isFromHomePage={isFromHomePage} user={user} />
                  </div>
                ))}
            </div>
          </div>
        </Row>
      ) : null}
    </>
  );
}
