import React from "react";
import { useEffect, useState } from "react";
import Link from "next/link";
import client from "../contentful/Contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

function WinningMetrics({ targetSite = "ShotQualityBets", targetPage = "Glossary" }) {
  const [winningMetrics, setWinningMetrics] = useState([]);
  useEffect(() => {
    client
      .getEntries({
        content_type: "winningMetrics",
        "fields.targetSite[in]": targetSite, // Filter by TargetSite
        "fields.targetPage": targetPage, // Filter by TargetPage
      })
      .then((response) => {
        setWinningMetrics(response.items);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <div
        className="p-4 pb-5 pt-5 winning-metrics"
        style={{
          backgroundColor: "#f3f4f6",
          backgroundColor: "#fff",
          borderTop: "1px solid #e5e5e5",
        }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className=" mb-4 color-orange text-uppercase fw-9">Winning Metrics</h1>
            <p>See How we do it.</p>
          </div>
          {winningMetrics.map((metric, index) => (
            <div className="col-12 col-md-4 p-4" key={index}>
              <Link
                className="is-link"
                href={`/glossary/` + encodeURIComponent(metric.fields.metricName)}
                target="_blank"
              >
                <div className="p-4 border-radius-10 box-shadow-1 h-100 glow-border">
                  <div>
                    <h3 className="color-orange fw-9">{metric.fields.metricName}</h3>
                    <h5 className="is-link">{documentToReactComponents(metric.fields.definition)}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WinningMetrics;
