const Lock = (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.25 8V5.5325C13.248 4.19841 12.7172 2.91952 11.7738 1.97618C10.8305 1.03283 9.55159 0.501984 8.2175 0.5H7.7825C6.44841 0.501984 5.16952 1.03283 4.22618 1.97618C3.28283 2.91952 2.75198 4.19841 2.75 5.5325V8C2.15326 8 1.58097 8.23705 1.15901 8.65901C0.737053 9.08097 0.5 9.65326 0.5 10.25V17.225C0.501983 18.3582 0.95302 19.4444 1.75431 20.2457C2.5556 21.047 3.64181 21.498 4.775 21.5H11.225C12.3582 21.498 13.4444 21.047 14.2457 20.2457C15.047 19.4444 15.498 18.3582 15.5 17.225V10.25C15.5 9.65326 15.2629 9.08097 14.841 8.65901C14.419 8.23705 13.8467 8 13.25 8ZM4.25 5.5325C4.25 4.59562 4.62217 3.69712 5.28465 3.03465C5.94712 2.37217 6.84562 2 7.7825 2H8.2175C9.15438 2 10.0529 2.37217 10.7154 3.03465C11.3778 3.69712 11.75 4.59562 11.75 5.5325V8H4.25V5.5325ZM14 17.225C14 17.961 13.7076 18.6668 13.1872 19.1872C12.6668 19.7076 11.961 20 11.225 20H4.775C4.03902 20 3.33319 19.7076 2.81278 19.1872C2.29237 18.6668 2 17.961 2 17.225V10.25C2 10.0511 2.07902 9.86032 2.21967 9.71967C2.36032 9.57902 2.55109 9.5 2.75 9.5H13.25C13.4489 9.5 13.6397 9.57902 13.7803 9.71967C13.921 9.86032 14 10.0511 14 10.25V17.225Z"
      fill="#0F131B"
    />
    <path
      d="M8.75 14.5412V17.0012C8.75 17.2001 8.67098 17.3909 8.53033 17.5316C8.38968 17.6722 8.19891 17.7512 8 17.7512C7.80109 17.7512 7.61032 17.6722 7.46967 17.5316C7.32902 17.3909 7.25 17.2001 7.25 17.0012V14.5412C6.96404 14.3761 6.74054 14.1213 6.61418 13.8162C6.48782 13.5111 6.46565 13.1729 6.55111 12.854C6.63657 12.535 6.82489 12.2532 7.08686 12.0522C7.34882 11.8511 7.6698 11.7422 8 11.7422C8.3302 11.7422 8.65118 11.8511 8.91314 12.0522C9.17511 12.2532 9.36343 12.535 9.44889 12.854C9.53435 13.1729 9.51218 13.5111 9.38582 13.8162C9.25946 14.1213 9.03596 14.3761 8.75 14.5412Z"
      fill="#0F131B"
    />
  </svg>
);

export default Lock;
